import { Injectable } from "@angular/core";
import { decodeJwt } from "jose";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, switchMap, take, tap } from "rxjs/operators";
import { GlobalApiCallsService } from "src/app/services/global-api-calls.service";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class CognitoAuthService {
	private _idToken: string;
	private email = "";
	private zonarOwnerIdCache$ = new BehaviorSubject<string | null>(null); // Cache latest value

	constructor(private globalApiCallsService: GlobalApiCallsService) {}

	static getEmailFromToken(token: string): string | null {
		try {
			const decoded = decodeJwt(token);
			return (decoded?.email as string) || null;
		} catch (error) {
			console.error("Invalid JWT:", error);
			return null;
		}
	}
	public get idToken() {
		return this._idToken;
	}

	public setIDToken(idToken: string) {
		this._idToken = idToken;

		this.resolveEmail();
	}

	resolveEmail() {
		this.email = CognitoAuthService.getEmailFromToken(this._idToken);
	}

	public get zonarOwnerId() {
		return this.zonarOwnerIdCache$.getValue();
	}
	public getZonarOwnerId(): Observable<string> {
		if (!this.zonarOwnerIdCache$.observers.length) {
			this.globalApiCallsService
				.lookupUserByEmail(this.email)
				.pipe(
					take(1),
					switchMap(users => this.globalApiCallsService.getUserProfile(users[0].id)),
					switchMap(userprofiles =>
						of(
							userprofiles.find(
								userprofile =>
									userprofile.applicationId === environment.environmentConstants.APP_APPLICATION_ID,
							),
						),
					),
					map(userprofile => `User ${userprofile.id}`),
					tap(zonarOwnerId => this.zonarOwnerIdCache$.next(zonarOwnerId)), // Cache result
				)
				.subscribe();
		}

		return this.zonarOwnerIdCache$.asObservable();
	}
}
