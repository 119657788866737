<div
	class="wrapper"
	fxLayout="column"
	*ngIf="hideSideNav !== null"
>
	<div fxLayout="row">
		<!-- Add the code below to the zui-sidenav to run the nav with local links when needed -->
		<!-- [menu]="SIDENAV_MENU" -->
		<ng-container *ngIf="!hideSideNav">
			<zui-sidenav
				class="sidenav-component"
				[sidenavParams]="SIDENAV_PARAMS"
				[footer]="sidenavFooter"
				(emitSelectedCompany)="switchToSelectedCompany($event)"
			>
				<div class="sidenav-content-container">
					<div>
						<div
							*ngIf="usingShadowEnvironment"
							class="shadow-cue"
						>
							Shadow Environment
						</div>
						<router-outlet></router-outlet>
					</div>

					<zui-footer-component class="footer"></zui-footer-component>
				</div>
			</zui-sidenav>
		</ng-container>
		<ng-container *ngIf="hideSideNav">
			<zui-sidenav
				class="sidenav-component"
				style="display: none"
				[sidenavParams]="SIDENAV_PARAMS"
				[footer]="sidenavFooter"
				(emitSelectedCompany)="switchToSelectedCompany($event)"
			></zui-sidenav>
			<div class="sidenav-content-container">
				<div>
					<div
						*ngIf="usingShadowEnvironment"
						class="shadow-cue"
					>
						Shadow Environment
					</div>
					<router-outlet></router-outlet>
				</div>

				<zui-footer-component class="footer"></zui-footer-component>
			</div>
		</ng-container>
	</div>
</div>
