import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { Tab } from "src/app/components/simple-tabs/simple-tabs.component";
import { LocalStateService } from "src/app/services/local-state/local-state.service";

export interface RouteLink {
	label: string;
	link?: string;
	subLabels?: ReadonlyArray<{ label: string; link: string }>;
}

interface TabData {
	route: string;
}
@Component({
	selector: "app-default-layout",
	templateUrl: "./default-layout.component.html",
	styleUrls: ["./default-layout.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class DefaultLayoutComponent implements OnInit {
	showTabs = false;

	tabs: Array<Tab<TabData>> = [
		{
			id: "inspection-list",
			label: "Inspection History",
			defaultActive: true,
			data: {
				route: "/inspection-list",
			},
		},
		{
			id: "defects",
			label: "Defects",
			defaultActive: true,
			data: {
				route: "/defects",
			},
		},
		{
			id: "missing-inspections",
			label: "Missing Inspections",
			defaultActive: true,
			data: {
				route: "/missing-inspections",
			},
		},
	];

	constructor(private router: Router, private localStateService: LocalStateService) {}

	ngOnInit(): void {
		this.showTabs = this.localStateService.getShowTabsMenu();
	}

	onTabClicked(tab: Tab<TabData>) {
		this.router.navigate([tab.data.route], {
			queryParams: {
				hideSideNav: this.localStateService.getShowTabsMenu(),
			},
		});
	}
}
