import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	OnDestroy,
	OnInit,
	Output,
	TemplateRef,
	ViewChild,
} from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { Select } from "@ngxs/store";
import { combineLatest, Observable, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { AppState } from "src/app/app.state";
import { translateAndFormat } from "src/app/i18next";
import { Asset, EvirDivisionsGetInner, InspectorsInner } from "src/app/models/openAPIAliases";
import { isDefined } from "src/utils/isDefined/isDefined";
import { searchCleanup } from "src/utils/searchCleanup/searchCleanup";
import { FilterCardComponent } from "../filter-card/filter-card.component";
import { Tab } from "../simple-tabs/simple-tabs.component";
import { getDisplayValue } from "./helpers/get-display-value";
import { getId } from "./helpers/get-id";
export interface TableRecord {
	fields: Array<TableRecordField>;
}

export interface TableRecordField {
	name: string;
	value: string;
}

export const TableName = {
	assets: "getAssetsLoader",
	divisions: "getDivisionsLoader",
	inspectors: "getInspectorsLoader",
} as const;

export type TableName = keyof typeof TableName;
export interface SearchTable {
	tableName: TableName;
	tableQueryName: string;
	displayTableName: string;
	fieldNames: ReadonlyArray<string>;
	displayFieldNames: ReadonlyArray<string>;
}

export interface TableFilterChip {
	displayValue: string;
	record: TableRecord;
	tableDisplayValue: string;
	tableName: string;
	tableQueryName: string;
}

enum State {
	Init,
	Searching,
	SearchCompleted,
}

export interface ViewRecord {
	record: TableRecord | null;
	displayValue: string;
}

const schemas = {
	assets: [
		{ name: "assetId" },
		{ name: "assetCategory" },
		{ name: "assetJurisdiction" },
		{ name: "assetVersion" },
		{ name: "assetDivisions" },
		{ name: "assetName" },
		{ name: "assetType" },
	],
	divisions: [{ name: "divisionId" }, { name: "divisionName" }, { name: "divisionType" }],
	inspectors: [{ name: "inspectorId" }, { name: "inspectorFirstName" }, { name: "inspectorLastName" }],
} as const;

const toRecord = (data: Record<string, string>, tableName: keyof typeof schemas) =>
	schemas[tableName].map(field => ({ ...field, value: data[field.name] }));

@Component({
	selector: "app-get-table-filter",
	templateUrl: "./get-table-filter.component.html",
	styleUrls: ["./get-table-filter.component.scss"],
})
export class GetTableFilterComponent implements OnInit, OnDestroy {
	@ViewChild("menuTrigger") menuTrigger: MatMenuTrigger;
	@ViewChild("virtualScroll") virtualScroll: TemplateRef<HTMLElement>;

	@Output() chip = new EventEmitter<TableFilterChip>();

	@Select(AppState.getSearchTables) searchTables$: Observable<Array<SearchTable>>;
	@Select(AppState.selectAssets) assets$: Observable<Array<Asset>>;
	@Select(AppState.selectDivisions) divisions$: Observable<EvirDivisionsGetInner>;
	@Select(AppState.selectInspectors) inspectors$: Observable<InspectorsInner>;

	tabs: Array<Tab<SearchTable>> = [];
	activeTab: Tab<SearchTable>;
	showOverlayContainer = false;
	pattern = "";
	resetInput = false;
	subscriptions = new Subscription();
	searchState = State.Init;
	State = State;
	viewRecords: Array<ViewRecord> = [];
	placeholder = translateAndFormat("search to filter", "capitalize");
	dictionary: Readonly<{
		assets: ReadonlyArray<Asset>;
		divisions: EvirDivisionsGetInner;
		inspectors: InspectorsInner;
	}> = {
		assets: [],
		divisions: [],
		inspectors: [],
	};

	constructor(
		// private localStoreService: LocalStoreService,
		private filterCard: FilterCardComponent,
		private _eref: ElementRef,
	) {}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	ngOnInit() {
		this.searchTables$.pipe(filter(isDefined)).subscribe(searchTables => {
			this.tabs = [];
			this.activeTab = undefined;

			// build the tabs
			searchTables.forEach(searchTable => {
				const tab: Tab<SearchTable> = {
					id: searchTable.tableName,
					label: searchTable.displayTableName,
					defaultActive: !this.activeTab,
					data: searchTable,
				};

				this.activeTab = this.activeTab ?? tab;

				this.tabs = [...this.tabs, tab];
			});
		});

		combineLatest([this.assets$, this.divisions$, this.inspectors$])
			.pipe(filter(isDefined))
			.subscribe(([assets, divisions, inspectors]) => {
				this.dictionary = { assets, divisions, inspectors };
			});
	}

	onSearchInputChanged(pattern: string) {
		this.pattern = pattern;

		this.search();
	}

	onTabClicked(tab: Tab<SearchTable>) {
		this.activeTab.defaultActive = false;
		tab.defaultActive = true;
		this.activeTab = tab;
		if (this.virtualScroll) {
			this.virtualScroll.elementRef.nativeElement.scrollTo(0, 0);
		}
		this.search();
	}

	search() {
		this.searchState = State.Searching;

		const records = this.dictionary[
			this.activeTab.data.tableName
		] as typeof this.dictionary[keyof typeof this.dictionary];

		const chips = new Set(
			this.filterCard.tableFilterChips?.map(
				({ record: { fields } }) => fields.find(field => field.name.endsWith("Id")).value,
			),
		);

		this.viewRecords = records
			?.map(data => {
				const displayValue = getDisplayValue(data, this.activeTab.data.tableName).trim();
				const displayId = getId(data, this.activeTab.data.tableName).trim();

				return {
					displayValue,
					record: { fields: toRecord(data, this.activeTab.data.tableName) },
					selected: chips.has(displayId),
				};
			})
			.filter(
				({ displayValue }) =>
					this.pattern === "" || searchCleanup(displayValue).includes(searchCleanup(this.pattern)),
			)
			.sort((a, b) => a.displayValue.localeCompare(b.displayValue));

		this.searchState = State.SearchCompleted;
	}

	onClickHighlightedText(viewRecord: ViewRecord) {
		this.showOverlayContainer = false;

		this.resetInput = false;

		this.chip.emit({
			displayValue: viewRecord.displayValue,
			record: viewRecord.record,
			tableDisplayValue: this.activeTab.data.displayTableName,
			tableName: this.activeTab.data.tableName,
			tableQueryName: this.activeTab.data.tableQueryName,
		});
	}

	onHasFocus() {
		this.resetInput = false;

		this.showOverlayContainer = true;

		this.search();
	}

	@HostListener("window:click", ["$event"])
	clickOutsideOverlay(event: MouseEvent) {
		if (!this._eref.nativeElement.contains(event.target)) {
			this.showOverlayContainer = false;

			this.resetInput = false;
		}
	}

	clearInput() {
		this.resetInput = true;
	}
}
