import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

export interface Tab<T> {
	id: string; // must be unique
	defaultActive: boolean;
	label: string;
	data: T;
}

@Component({
	selector: "app-simple-tabs",
	templateUrl: "./simple-tabs.component.html",
	styleUrls: ["./simple-tabs.component.scss"],
})
export class SimpleTabsComponent<T> implements OnInit {
	@Input()
	tabs: Array<Tab<T>> = [];

	@Output()
	clicked = new EventEmitter<Tab<T>>();

	activeTab: Tab<T>;

	ngOnInit() {
		this.activeTab = this.tabs.find(tab => tab.defaultActive);
	}

	onClickTab(tab: Tab<T>) {
		event.stopPropagation();

		this.activeTab = tab;

		this.clicked.emit(tab);
	}
}
